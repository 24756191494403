<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data="de membres du staff présents" @refresh="refresh"/>

    <b-row>
      <b-col>
        <b-check v-model="federalRollNumberVisible" switch>Afficher les n° de licence</b-check>
      </b-col>
      <b-col>
        <b-check v-model="capabilitiesVisible" switch>Afficher les compétences</b-check>
      </b-col>
    </b-row>
    <detailed-staff-list
            :items="staff"
            :canSelect="!disabled && d_competition.COMPETITION_TYPE.isPartOfCdfCva"
            :showRollnumber="federalRollNumberVisible"
            :showCapabilities="capabilitiesVisible"
            :canAdd="!disabled && (!d_competition.COMPETITION_TYPE.isPartOfCdfCva || d_competition.isOpen && false)"
            :canEdit="!d_competition.COMPETITION_TYPE.isPartOfCdfCva || d_competition.isOpen && false"
            :canRemove="!disabled && (!d_competition.COMPETITION_TYPE.isPartOfCdfCva || d_competition.isOpen && false)"
            :canSetChiefJudges="!disabled"
            @selectionChanged = "staffMemhers_selectionChanged"
            @chiefJudgeStateChanged = "stafMembers_chiefJudgeStateChanged"
          @editLicensee = 'staffMembers_editLicensee'
  ></detailed-staff-list>

    <!-- Dialogue de traitement de l'état de validité de la license d'un pilote -->
    <licensee-edit-dialog
      ref="licenceDialog"
      v-model="showEditLicenseeDialog"
      @hide="showEditLicenseeDialog = false"
      @ok="editLicenseeDialog_ok"
      :federalNumber="selectedLicensee"
      :year="selectedLicenceYear"
      :peopleName="selectedLicencePeopleName"
    >
    </licensee-edit-dialog>

  </div>
</template>

<script>
import {  RST_COMPETITION_STAFF, PEOPLE } from "@/LocalDB";

import ModelViewsHelper from "@/services/store/models/ModelViews";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import DetailedStaffList from '@/components/DetailedStaffList';
import DataSyncState from '@/components/Utilities/DataSyncState.vue';
import LicenseeEditDialog from './LicenseeEditDialog.vue';

export default{
  model:{
    prop: 'competition',
  },
  props:{ 
    competition: { type:Object, required:true },
    disabled: { type:Boolean, default:false }
  },
  components: {  DetailedStaffList, DataSyncState, LicenseeEditDialog /*ModelViewsHelper,*/ },
  computed:{
/*    competition_id(){
      return this.competition ? this.competition.id : null;
    },*/
/*    staff(){
      return ModelViewsHelper.getDetailedStaffListItems(this.competition.competition_type, this.competition.scope,this.competition.year, null)
                              .filter(item => item.isFederalActive)
                              .map(item => {
                                var st = RST_COMPETITION_STAFF.query().where('competition_id', this.currentCompetition.id).where('staff_id', item.id).first();
                                if(st != null){
                                    item.isSelected = true;
                                    item.cannotBeSelected = st.hasAction;
                                    item.isChiefJudge = st.isChiefJudge;
                                }
                                return item;
                            });
    },*/
  },
  data(){
    return {
      d_competition: this.competition,

      selected_member_id: null,

      federalRollNumberVisible: true,
      capabilitiesVisible:true,
      dataSynchronized: null ,

      showEditLicenseeDialog: false,
      selectedLicensee: '',
      selectedLicencePeopleName: '',
      selectedLicenceYear: null,
    
      staff: [],
    }
  },
  methods:{
    async staffMemhers_selectionChanged(element, cancel){
      if(this.disabled)
      {
        cancel();
        return;
      }
      try{
        await CompetitionsConfigurationHelper.refreshStaffMembersAync(this.d_competition.id);
        var member = RST_COMPETITION_STAFF.query().where("competition_id", this.d_competition.id).where("staff_id", element.id).where(item => item.event == null).first();
        console.log(element, member);
        if(member && member.hasAction){
          cancel();
          await this.$bvModal.msgbox(member.fistname + ' a déjà participé à une épreuve et ne peut donc plus être supprimé de la liste des membres du staff pour cette compétition !', { centered: true, titleHtml: '<span style="background-color: var(--danger)">Opération impossible</span>', hideHeaderClose:false, "header-bg-variant": 'danger' , okVariant: 'danger'})
          return;
        }
        if(member && member.isPlannedForEvent){
          var confirmation = await this.$bvModal.msgBoxConfirm(member.firstname + " est prévu pour participer dans au moins une épreuve (non commencée). En choisissant de continuer, il sera retiré de toutes les épreuves pour lesquelles il ou elle est planifié(e). Etes-vous certain de vouloir continuer ?", { headerBgVariant: "warning", title: 'Attention !', okVariant:"warning", okTitle:"Oui, supprimer du staff", cancelTitle:'Non'});
          if(!confirmation){
            cancel();
            return;
          }
        }
        await this.$showSaver()
        var success = CompetitionsConfigurationHelper.toggleStaffMemberPresenceAsync(this.d_competition.id, element.id);
        this.toastAsyncSaved(success);
          this.$hideSaver();
      }catch(error){
        console.log('staffMemhers_selectionChanged ERROR ', error);
        this.toastSaveFailure();
        await this.refresh();
      }
    },
    async stafMembers_chiefJudgeStateChanged(element)
    {
      if(this.disabled) return;
      try{
        await this.$showSaver()
        var success = await CompetitionsConfigurationHelper.toggleStaffMemberMainChiefJudgeStateAsync(this.d_competition.id, element.id);
        this.toastAsyncSaved(success);
        this.$hideSaver();
      }catch(error)
      {
        console.log('stafMembers_chiefJudgeStateChanged ERROR ', error);
        this.toastSaveFailure();
        await this.refresh();
      }
    },
    staffMembers_editLicensee(member){
      if(this.disabled)
        return;
      this.selected_member_id = member;
      var people = PEOPLE.query().where("id", member.id).first();
      this.selectedLicensee = people.federalNumber;
      this.selectedLicencePeopleName= people.name;
      this.selectedLicenceYear = this.d_competition ? this.d_competition.year : new Date().getFullYear();
      this.showEditLicenseeDialog = true;
    },
    async editLicenseeDialog_ok(){
      await this.refresh();
    },
    async refresh(){
      await this.$showRefresher();
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshStaffMembersAync(this.d_competition.id);
      /*this.fields = RST_FIELD.query()
        .where("competition_id", this.competition_id)
        .orderBy("field_number")
        .get()
        .map(item => {
          return { field: item, isRemovable: !item.isMain, canChangeHotState : false }
        });*/
      this.staff = ModelViewsHelper.getDetailedStaffListItems(this.d_competition.competition_type, this.d_competition.scope,this.d_competition.year, null)
                              .filter(item => item.isFederalActive)
                              .map(item => {
                                var st = RST_COMPETITION_STAFF.query().where('competition_id', this.d_competition.id).where('staff_id', item.id).first();
                                if(st != null){
                                    item.isSelected = true;
                                    item.cannotBeSelected = st.hasAction;
                                    item.isChiefJudge = st.isChiefJudge;
                                }
                                return item;
                            });
      if(this.disabled)
        this.staff = this.staff.filter(s => s.isSelected);
        
      console.log('StaffmembersTab.refresh => revoir le chargement de la liste');
      this.$hideRefresher();
    }
  },
  async mounted(){
    console.log('StaffmembersTab mounted');
    await this.refresh();
  },
  watch:{
    competition: function(newValue){
      this.d_competition = newValue;
    }
  }
}
</script>
