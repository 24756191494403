<template>
    <b-container fluid >
        <!--<b-row v-if="!hasSlot('briefing')">-->
            <slot name="briefing">
                <b-button 
                    class="event-launcher-palet" 
                    @click="launchBriefingBtn_click"
                    :disabled="briefingState.disabled"
                    :variant="briefingState.variant"
                    style="width:100%;"
                >
                    {{ briefingState.text }}
                </b-button>
            </slot>
        <!--</b-row>-->

                <round-events-launcher
                    :round = "round"
                />
        <div style="width:100%; display:flex; justify-content: space-between;">
                <b-button 
                    class="event-launcher-palet" 
                    @click="launchDebriefingBtn_click"
                    :disabled="debriefingState.disabled"
                    :variant="debriefingState.variant"
                    style="width:/*45*/100%;"
                >{{ debriefingState.text }}</b-button>
                <b-button v-if="false"
                    class="event-launcher-palet" 
                    @click="launchResults_click"
                    :disabled="resultsState.disabled"
                    :variant="resultsState.variant"
                    style="width:45%;"
                >{{ resultsState.text }}</b-button>
        </div>
    </b-container>
</template>

<script>
import {RST_ROUND} from "@/LocalDB"
import SynchroHelper from '../../../../services/helpers/synchroHelper';
import RoundEventsLauncher from './RoundEventsLauncher.vue'

export default {
  components: { RoundEventsLauncher },
    props:{
        //round_number: { type:Number, required:true },
        round: { type:Object, required:true }
    },
    data(){
        return {
            //d_round_number: this.round_number,
            //briefingState: { text: 'Démarrer le briefing', variant: 'primary', disabled: true },
            //debriefingState: { text: 'Démarrer le dé-briefing', variant: 'primary', disabled: true },
            //resultsState: { text: 'Voir les résultats de la manche', variant: 'primary', disabled: true},
        };
    },
    computed:{
        briefingState(){
            if(this.round.isBriefingCompleted)
                return  { text: 'Revoir le briefing', variant: 'success', disabled: false };
            else if (this.round.isBriefingStarted)
                return { text: 'Reprendre le briefing', variant: 'warning', disabled: false };
            else if (this.round.isReadyToBriefing)
                return { text: 'Démarrer le briefing', variant: 'primary', disabled: false };
            else if (this.round.canBePrepared && this.canPrepareBrief)
                return { text: 'Démarrer (ou préparer) la manche / le briefing', variant: 'primary', disabled: false};
            else
                return { text: 'Démarrer le briefing', variant: 'outline-danger', disabled: true };
        },
        canPrepareBrief(){
            var userVisa = this.currentUser.visa;
            if(userVisa == this.currentCompetition.owner) return true;
            if(this.currentCompetition.ADMINS.findIndex(a => a.visa == userVisa) >= 0) return true;
            if(this.round.CHIEF_JUDGE && this.round.CHIEF_JUDGE.visa == userVisa) return true;
            return false;
        },
        debriefingState(){
            if(this.round.isDebriefingCompletedOrSkipped)
                //return { text: 'Revoir le dé-briefing', variant: 'success', disabled: false };
                return { text: 'Revoir le dé-briefing', variant: 'success', disabled: true };
            else if (this.round.isDebriefingStarted)
                return { text: 'Reprendre le dé-briefing', variant: 'warning', disabled: false };
            else if (this.round.isCompleted || this.round.areAllEventsCompleted)
                return { text: 'Démarrer (ou passer) le dé-briefing', variant: 'primary', disabled: false };
            else if(!this.round.isBriefingCompleted)
                return {text: 'Démarrer (ou passer) le dé-briefing', variant: 'outline-danger', disabled: true};
            else
                return { text: 'Démarrer (ou passer) le dé-briefing', variant: 'outline-primary', disabled: true };
        },
        resultsState(){
            if(this.round.areAllEventsCompleted)
                return { text: 'Voir tous les résultats de la manche', variant: 'success', disabled: false};
            else if(!this.round.isBriefingCompleted)
                return { text: 'Voir tous les résultats de la manche', variant: 'outline-danger', disabled: true};
            else
                return { text: 'Voir tous les résultats de la manche', variant: 'outline-primary', disabled: true};
        }
    },
    methods: {
        async launchBriefingBtn_click(){
            if(this.round.isReadyToBriefing && !this.round.isBriefingStarted){
                await this.showSaver();
                var data = {
                    isBriefingStarted: true,
                    isBriefingCompleted: false,
                    date: new Date().toISOString()
                };
                RST_ROUND.update({where:this.round.id, data:data});
                var operationId = SynchroHelper.LogOperation("UPDATE", "RST_ROUND", this.round.id, data, "BRIEFING_STATUSES");
                var result = await SynchroHelper.TryToSynchronizeAsync([operationId]);
                this.toastAsyncSaved(result,"Le lancement du briefing est initié.");
                this.hideSaver();
            }
            this.showLoader();
            this.setCurrentRound_number(this.round.round_number);
            this.$router.push({ name: 'RoundBriefing'});
        },
        async launchDebriefingBtn_click(){
            if(this.round.isCompleted || this.round.isDebriefingCompletedOrSkipped)
                return;
            if(await this.$bvModal.msgBoxConfirm('Cette action, supposée être la dernière pour ce tour, mettra fin au tour/manche de façon définitive, que vous réalisiez ou non un débriefing pour le tour.\nEtes-vous sûr de vouloir mettre fin maintenant à cette manche ?', { okTitle: 'Oui', okVariant: 'danger', cancelTitle:'Non'}))
            {
                await this.showSaver();
                var withDebrief = await this.$bvModal.msgBoxConfirm("Avez-vous déjà fait, ou avez-vous l'intention de faire, un débriefing ?", { okTitle: 'Oui', cancelTitle:'Non'})
                var upd = {
                    isCompleted: true,
                    isDebriefingCompletedOrSkipped: withDebrief,
                };
                RST_ROUND.update({where: this.round.id, data:upd});
                var operationIds = [];
                operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", this.round.id, upd, "IS_COMPLETED"));
                operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", this.round.id, upd, "DEBRIEFING_STATE"));
                var result = await SynchroHelper.TryToSynchronizeAsync(operationIds);
                this.toastAsyncSaved(result, 'Tour marqué comme étant "terminé"');
                this.hideSaver();
            }
            //this.setCurrentRound_number(this.round.round_number);
            //this.$router.push({ name: 'RoundDebriefing'});
        },
        async launchResults_click(){
            this.showLoader();
            this.setCurrentRound_number(this.round.round_number);
            this.$router.push({ name: 'RoundResults'})
        },
    }
}
</script>

<style>
    .event-launcher-palet{ min-height: 4rem !important; margin:5px;}
    .event-launcher-palet.event{ min-width: 210px !important; }
</style>