<template>
  <div>
    <b-row>
      <b-col v-for="event in eventTypes" :key="event.code">
      <form-field
        :caption="'Epreuves de type ' + event.name"
        required
      >
        <number-of-rounds-editor
          v-model="event.nbEvents"
          :minRoundsToKeep="event.nbMinEvents"
          :canRemoveRound="true"
          :canAddRound="true"
          @addRound="addEvent(event.code, event.displayOrder)"
          @removeRound="removeEvent(event.code)"
        />
      </form-field>
      </b-col>
    </b-row>
    <form-field caption="Figures des épreuves avec figure" v-if="eventsWithCompulsory.length > 0">
      <b-tabs vertical pills lazy>
        <b-tab v-for="event in eventsWithCompulsory" :key="event.id" :title="event.shortname">
          <event-compulsories
            :competition_id="d_competition_id"
            :level="d_level"
            :event="event.event"
            :round_number="event.round_number"
          ></event-compulsories>
        </b-tab>
      </b-tabs>
    </form-field>
  </div>
</template>

<script>
import { RST_COMPETITION, SEASON_COMPETITION_TYPE_SCOPE_EVENT, RST_REF_EVENT } from "@/LocalDB";
//import DataSyncState from '@/components/Utilities/DataSyncState.vue';
import FormField from '@/components/Panels/FormField.vue';
import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import EventCompulsories from './EventCompulsories.vue';
//import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

export default {
  model:{
    prop: 'competition_id',
  },
  props:{
    competition_id: { type:String, required:true },
    level: { type:String, required:true },
    regulation: { type:String, required:true},
  },
  components: { FormField, NumberOfRoundsEditor, EventCompulsories, /*DataSyncState,*/ /*CollapsePanel ,*/ },
  data(){
    return {
      d_competition_id: this.competition_id,
      d_level: this.level,

//      dataSynchronized: null,
      nbPrecision: 0,
      nbBallet: 0,
      nbMix: 0,
/*      precisionAvailable: false,
      balletAvailable: false,
      mixAvailable: false,*/
      events:[],
      eventTypes:[],
    }
  },
  computed:{
    eventsWithCompulsory(){
      return this.events.filter(evt => evt.EVENT.hasCompulsories);
    },
    nbPrecisionEvents:{
      get() { return this.nbPrecision;},
      set(value){ this.nbPrecision = value; }
    },
    nbBalletEvents:{
      get() { return this.nbBallet;},
      set(value){ this.nbBallet = value; }
    },
    nbMixEvents:{
      get() { return this.nbMix;},
      set(value){ this.nbMix = value; }
    },
/*    minPrecisionEvents(){ return 0; },
    minBalletEvents() { return 0; },
    minMixEvents() { return 0; },
    maxPrecisionEvents(){ return 3; },
    maxBalletEvents(){ return 3; },
    maxMixEvents(){ return 3; },*/
  },
  methods:{
    async refresh(){
      await this.$showRefresher();
      var dataSynchronized = await CompetitionsConfigurationHelper.refreshEvents(this.competition_id);
      this.events = RST_REF_EVENT.query().where('competition_id', this.d_competition_id).where("level", this.d_level).get();
      
      var COMPET = RST_COMPETITION.query().where('id', this.d_competition_id).first();
      var EVENTS = SEASON_COMPETITION_TYPE_SCOPE_EVENT.query()
        .where('competition_type', COMPET.competition_type)
        .where('year', COMPET.year)
        .where('scope', COMPET.scope)
        .orderBy('displayOrder')
        .get();

      this.eventTypes = EVENTS.map(evtType => {
            var level_type_events = this.events.filter(evt => evt.event == evtType.event);
            var started = level_type_events.filter(evt => evt.isStarted || evt.isCompleted).length;
            var total = level_type_events.length;
            return {...evtType.EVENT,
              nbMinEvents: started,
              nbEvents: total,
              nbMaxEvents: 3
            };
          });

      this.$hideRefresher();
      this.$emit("dataSynchronisationStateChange", dataSynchronized);
    },
    async addEvent(eventType, displayOrder){
      //console.log('addEvent', eventType, this.d_level, displayOrder);
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.createEventAsync(this.d_competition_id, eventType, this.d_level, displayOrder);
        this.toastAsyncSaved(result, "Epreuve ajoutée");
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error)
      {
        this.toastSaveFailure("Echec de la création de l'épreuve : " + error);
      }
      this.$hideSaver();
    },
    async removeEvent(eventType){
      console.log('removeEvent', eventType, this.d_level);
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.deleteEventAsync(this.d_competition_id, eventType, this.d_level);
        this.toastAsyncSaved(result, "Epreuve supprimée");
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error)
      {
        this.toastSaveFailure("Echec de la suppression de l'épreuve : " + error);
      }
      this.$hideSaver();
    }
  },
  async mounted(){
    //console.log('EventsAdminFrCdfVa mounted', this.competition_id, this.d_competition_id);
    await this.refresh();
  },
  watch:{
    competition_id: function(newVal){
      this.d_competition_id = newVal;
      this.refresh();
    },
    level: function(newVal){
      this.d_level = newVal;
      this.refresh();
    }
  }
}
</script>
